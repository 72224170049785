import React from 'react';
import Accordion from '../components/Accordion';
import Layout from '../components/Layout';

const faqs = (): React.ReactElement => {
  return (
    <Layout>
      <div id="faqs">
        <Accordion title="Preciso ter uma conta?">
          <p className="paragraph">
            Sim, a única forma conseguires publicar anúncios e utilizar a app é fazendo o registo.
            Esse processo é rápido e gratuito.
          </p>
        </Accordion>
        <Accordion title="Preciso inserir um objeto?">
          <p className="paragraph">
            Sim, a única forma de começares a utilizar a app é inserindo, pelo menos, um anúncio. Só
            assim é que poderás ter acesso ao feed de trocas.
          </p>
        </Accordion>
        <Accordion title="Criar um anúncio">
          <p className="paragraph">
            Para criares um anúncio basta ires à página principal e clicar no botão +. De seguida
            podes inserir as fotografias do teu item, o nome que queres atribuir ao mesmo, a
            descrição do item (quanto mais informação e mais precisa for, maior a probabilidade de
            conseguires trocar o teu item), a categoria e subcategoria e o valor que atribuis ao teu
            objeto.
          </p>
        </Accordion>
        <Accordion title="Como criar conta na Suop?">
          <p className="paragraph">
            Para criares uma conta na Suop basta fazeres o download da app e inserires o teu email e
            password.
          </p>
        </Accordion>
        <Accordion title="Foto de perfil">
          <p className="paragraph">
            Ao fazeres o teu registo podes inserir uma fotografia de perfil. Caso não pretendas
            inserir a tua fotografia, podes também optar por selecionar um Avatar da lista
            disponibilizada.
          </p>
        </Accordion>
        <Accordion title="Existe limite de anúncios?">
          <p className="paragraph">
            Sim, na versão gratuita existe um limite de 5 anúncios. Caso queiras publicar mais
            anúncios poderás subscrever essa funcionalidade ou optar pela versão premium da app.
          </p>
        </Accordion>
        <Accordion title="Recuperar a palavra-passe">
          <p className="paragraph">
            Na página de registo, seleciona a opção “Recuperar palavra-passe”. Insere o teu email e
            irás receber um email na tua caixa de correio com as instruções para alterares a tua
            password. Depois de concluída a criação de uma nova password, volta à app e insere o teu
            email e a nova password.
          </p>
        </Accordion>
        <Accordion title="Sistema de classificações">
          <p className="paragraph">
            Sempre que efetuas uma troca podes classificar o outro utilizador. No chat que tens com
            esse utilizador, selecionas os 3 pontos que aparecem no canto superior direito e
            escolhes a opção confirmar troca. Automaticamente irá aparecer um campo onde podes
            atribuir uma classificação entre 1 e 5 estrelas.
          </p>
        </Accordion>
        <Accordion title="Mensagens/Matches">
          <p className="paragraph">
            Nesta opção, tens acesso a todas as mensagens trocadas com os outros utilizadores. Em
            cada chat é possível visualizar os dois itens que fizeram match e, caso o outro
            utilizador tenha mais itens publicados na Suop, consegues ver os restantes itens dele.
            Sabendo que ele também tem acesso aos teus, pode facilitar a troca de mais itens para
            além dos que já fizeram match. Consegues também ver qual é a classificação do outro
            utilizador.
          </p>
        </Accordion>
        <Accordion title="Como denunciar outro utilizador?">
          <p className="paragraph">
            Se estiveres a receber mensagens indesejadas ou ofensivas, podes denunciar o outro
            utilizador. Basta ires ao chat, selecionar os 3 pontos no canto superior direito e
            escolher a opção “Denunciar”. Irá aparecer uma caixa onde pode inserir o motivo da
            denúncia.
          </p>
        </Accordion>
        <Accordion title="Como denunciar um objeto?">
          <p className="paragraph">
            No feed de trocas, podes selecionar um item e no fim da descrição tens a opção de
            denunciar anúncio.
          </p>
        </Accordion>
        <Accordion title="Dicas de segurança">
          <p className="paragraph">
            Recomendamos sempre segurança máxima nas trocas. Existem alguns procedimentos que deves
            adotar durante o processo de troca: Se desconfiares de algum negócio, não o faças; Usa
            sempre o chat da Suop para ficares com o histórico da troca; Não envies dados pessoais
            que consideres confidenciais; Quando te encontrares com a outra pessoa para efetuar a
            troca, combina num lugar público.
          </p>
        </Accordion>
        <Accordion title="Pagamento">
          <p className="paragraph">
            Os pagamentos serão efetuados na app através de cartão de crédito.
          </p>
        </Accordion>
        <Accordion title="Dados Pessoais">
          <p className="paragraph">
            Podes sempre alterar os teus dados ao editar o perfil. É importante manteres os teus
            dados atualizados.
          </p>
        </Accordion>
        <Accordion title="Cancelar a conta">
          <p className="paragraph">
            Podes cancelar a tua conta a qualquer altura. Vais ao teu perfil e escolhes a opção
            Cancelar Conta. Depois segues os passos indicados. Podes deixar o teu comentário para
            sabermos o motivo que te leva a cancelar a conta. Para garantirmos a qualidade e
            integridade da app, a Suop fará a verificação dos itens antes de estarem visíveis na
            app. Caso haja motivo relevante, a Suop poderá retirar um anúncio ou até banir um
            utilizador da app
          </p>
        </Accordion>
        <Accordion title="Porquê que tenho de inserir um valor se o objetivo é a troca?">
          <p className="paragraph">
            Para os outros utilizadores terem noção do valor que atribuíste ao teu objeto e
            procurarem por valores idênticos ao seu.
          </p>
        </Accordion>
        <Accordion title="Localização">
          <p className="paragraph">
            Ao utilizares a app estás a dar a tua localização aproximada para que a app te possa
            devolver os objetos/itens que estão mais perto de ti, facilitando assim a troca.
          </p>
        </Accordion>
      </div>
    </Layout>
  );
};

export default faqs;
