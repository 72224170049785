import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

import styles from './Accordion.module.scss';

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  startsOpen?: boolean;
}

const Accordion = ({ title, children, startsOpen }: AccordionProps): React.ReactElement => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(!!startsOpen);
  return (
    <section className={styles.accordion + (isAccordionOpen ? ' ' + styles.accordionOpen : '')}>
      <div
        className={styles.accordionBox}
        onClick={() => {
          setIsAccordionOpen(!isAccordionOpen);
        }}
      >
        <h4 className={styles.titleBox}>{title}</h4>
        <FaChevronDown className={styles.icon} />
      </div>
      <div className={styles.body}>{children}</div>
    </section>
  );
};

export default Accordion;
